





import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import buttonThrottle from '@/assets/js/decorator/buttonThrottle'
@Component
export default class Test extends Vue {
  isGetting = false
  @buttonThrottle('isGetting')
  async confirm(): Promise<void> {
    await this.$http.post('/auth/captcha_login_img_get')
  }
}
