export default function buttonThrottle(str: string): any {
  return function (target: any, name: string): any {
    const btnClickFunc = target[name]
    const newFunc = async function (this: Vue & { [key: string]: boolean }, ...params: any[]) {
      if (this[str]) {
        return
      }
      this[str] = true
      try {
        await btnClickFunc.apply(this, params)
      } catch (error) {
        console.error(error)
      }
      this[str] = false
    }
    target[name] = newFunc
    return target
  }
}
